import { ProjectType } from 'const';
import { Schemas } from './models';

export const DefaultAlign = 'left';
export const DefaultVAlign = 'top';

export const UL_REG_EXP = /<ul[^>]*>((?:.|\n)*?[<\/li>|\n])<\/ul>/ig;
export const LI_REG_EXP = /<li[^>]*>((?:.|\n)*?)<\/li>/ig;

export enum CellEntityType {
  TEXT = 'Text',
  IMAGE = 'Image',
  BUTTON = 'Button',
  BULLET = 'Bullet',
  SPACER = 'Spacer',
}

export enum LayoutType {
  REGULAR = 'Regular',
  GROUPED = 'Grouped',
}

export enum SegmentType {
  TEXT = 'Text',
  LINK = 'Link',
  NO_WRAP = 'NoWrap',
}

// TODO: add ORDERED_LIST + ORDERED_LIST_ITEM for ref cit
export enum ParagraphType {
  PLAINT_TEXT = 'PlainText',
  UNORDERED_LIST = 'UnorderedList',
  UNORDERED_LIST_ITEM = 'UnorderedListItem',
}

export const DefaultFontFamily = 'Arial, Helvetica, \'sans-serif\'';

export const NEW_LINE_REG_EXP = /\n/g;
export const NEW_LINE_DELIMITER = '<br>';
export const URL_REG_EXP = /(https?)(:\/\/[\s/.a-zA-Z0-9_-]*)(\.[^\s]+)/g;
export const URL_WITH_JOINERS_REG_EXP = '$1&#8288;$2&#8288;$3';

export const ListIndents: Record<ProjectType, Schemas.ListIndentStyles> = {
  [ProjectType.BRAND_ALERT_MEDSCAPE]: {
    margin: '0px 0px 0px 1.5em',
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.CARD]: {
    margin: '0px 0px 0px 1.5em',
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.DISPLAY_PANEL]: {
    margin: '0px 0px 0px 1.5em',
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.EMAIL]: {
    margin: '0px 0px 0px 1.5em',
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.IPAD]: {
    margin: '0px 0px 0px 1.5em',
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.MOBILE_ALERT]: {
    margin: '0px 0px 0px 1.5em',
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.MOBILE_ALERT_DOXIMITY]: {
    margin: '0px 0px 0px 1.5em',
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.MOBILE_ALERT_EPOCRATES]: {
    margin: '0px 0px 0px 1.5em',
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.UNIVERSAL_ARTBOARD]: {
    margin: '0px 0px 0px 1.5em',
    padding: '0px 0px 0px 0px',
  },
};

export const REFERENCE_CITATION_INDENT = '2.3ch';
export const ReferenceCitationIndents: Partial<Record<ProjectType, Schemas.ListIndentStyles>> = {
  [ProjectType.BRAND_ALERT_MEDSCAPE]: {
    margin: `0px 0px 0px ${REFERENCE_CITATION_INDENT}`,
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.DISPLAY_PANEL]: {
    margin: `0px 0px 0px ${REFERENCE_CITATION_INDENT}`,
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.IPAD]: {
    margin: `0px 0px 0px ${REFERENCE_CITATION_INDENT}`,
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.MOBILE_ALERT]: {
    margin: `0px 0px 0px ${REFERENCE_CITATION_INDENT}`,
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.MOBILE_ALERT_DOXIMITY]: {
    margin: `0px 0px 0px ${REFERENCE_CITATION_INDENT}`,
    padding: '0px 0px 0px 0px',
  },
  [ProjectType.MOBILE_ALERT_EPOCRATES]: {
    margin: `0px 0px 0px ${REFERENCE_CITATION_INDENT}`,
    padding: '0px 0px 0px 0px',
  },
};
