import { URL_REG_EXP, URL_WITH_JOINERS_REG_EXP } from '../constants';

/**
* Inserts an invisible joiners (&#8288;) into a URLs.
* This is used to prevent URL recognition as auto-links in emails.
*
* @param {string} text - The string containing URLs.
* @returns {string} - The modified string with joiners inserted.
*/
export const addInvisibleJoinersToUrls = (text: string): string => (text.replace(URL_REG_EXP, URL_WITH_JOINERS_REG_EXP));
